import Resolution from "../Resolution";

export default async function StartCamera(videoEl, streamRef, videoPlaying, setVideoPlaying) {
    try {
        const res = Resolution();

        if (videoEl && !videoPlaying) {
            const [num, den] = res.aspect.split("/").map(Number);

            const constraints = {
                width: { ideal: res.width },
                height: { ideal: res.height }, 
                aspectRatio: num/den,
                facingMode: "user",
                advanced: [{sharpness: 7, saturation: 50, brightness: 200, colorTemperature: 2800, whiteBalanceMode: "continuous"
                }]
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            })

            if (!videoEl.srcObject) {
                const track = mediaStream.getVideoTracks()[0];
                track.applyConstraints(constraints);
                //const cap = track.getCapabilities();
                //console.log(cap);
                videoEl.srcObject = mediaStream;
                streamRef = mediaStream;

                videoEl.onloadedmetadata = () => {
                    const playPromise = videoEl.play();
    
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            setVideoPlaying(true);
                        }).catch((error) => {
                            alert("Play error:", error);
                        });
                    }
                };
            }
        }
        
    } catch (error) {
        alert("Error in opening camera:" + error)
    }
}